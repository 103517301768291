import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from 'vue-router'
import {ElLoading} from 'element-plus';
import {getKeyVal,localData} from '@/lib/data';
import store from '@/store';
import {Token} from '@/lib/access';

let loadingInstance: any;

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('@/components/layout/LayoutPage.vue'),
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('@/views/Index.vue'),
                meta: {title: '主页'}
            },
            {
                path: '/explore',
                name: 'explore',
                component: () => import('@/views/Explore.vue'),
                meta: {title: ''}
            },
            {
                path: '/message',
                name: 'message',
                component: () => import('@/views/Message.vue'),
                meta: {title: '通知'}
            },
            {
                path: '/referrer',
                name: 'referrer',
                component: () => import('@/views/Referrer.vue'),
                meta: {title: '关注列表'}
            },
            {
                path: '/private',
                name: 'private',
                component: () => import('@/views/Private.vue'),
                meta: {title: '私信'}
            },
            {
                path: '/forum',
                name: 'circle',
                component: () => import('@/views/Circle.vue'),
                meta: {title: '论坛'}
            },
            {
                path: '/forums',
                name: 'forums',
                component: () => import('@/views/HotCircle.vue'),
                meta: {title: '论坛'}
            },
            {
                path: '/userDetail',
                name: 'userDetail',
                component: () => import('@/views/Self.vue'),
            },
            {
                path: '/more',
                name: 'more',
                component: () => import('@/views/More.vue'),
            },
            {
                path: '/tweet',
                name: 'tweet',
                component: () => import('@/views/Tweet.vue'),
                meta: {title: '推文'}
            },
            {
                path: '/userReply',
                name: 'tweetUserReply',
                component: () => import('@/views/UserReply.vue'),
                meta: {title: '推文'}
            },
            {
                path: '/tweets',
                name: 'tweets',
                component: () => import('@/views/MoreTweet.vue'),
            },
            {
                path: '/download',
                name: 'download',
                component: () => import('@/views/DownLoad.vue'),
                meta: {title: '客户端下载'}
            },
            {
                path: '/setting',
                component: () => import('@/views/Setting.vue'),
                meta: {title: '设置'},
                children: [
                    {
                        path: '/setting/account',
                        name: 'settingAccount',
                        component: () => import('@/views/setting/Account.vue'),
                    },
                    {
                        path: '/setting/account/info',
                        name: 'settingAccountSet',
                        component: () => import('@/views/setting/AccountSet.vue'),
                    },
                    {
                        path: '/setting/account/name',
                        name: 'settingAccountName',
                        component: () => import('@/views/setting/AccountName.vue'),
                    },
                    {
                        path: '/setting/account/region',
                        name: 'settingAccountRegion',
                        component: () => import('@/views/setting/AccountRegion.vue'),
                    },
                    {
                        path: '/setting/account/sex',
                        name: 'settingAccountSex',
                        component: () => import('@/views/setting/AccountSex.vue'),
                    },
                    {
                        path: '/setting/account/password',
                        name: 'settingAccountPassword',
                        component: () => import('@/views/setting/AccountPassword.vue'),
                    },
                    {
                        path: '/setting/access',
                        name: 'settingAccess',
                        component: () => import('@/views/setting/Access.vue'),
                    },
                    {
                        path: '/setting/access/set',
                        name: 'settingAccessSet',
                        component: () => import('@/views/setting/AccessSet.vue'),
                    },
                    {
                        path: '/setting/access/verify',
                        name: 'settingAccessVerify',
                        component: () => import('@/views/setting/AccessVerify.vue'),
                    },
                    {
                        path: '/setting/other',
                        name: 'settingOther',
                        component: () => import('@/views/setting/Other.vue'),
                    },
                    {
                        path: '/setting/other/style',
                        name: 'settingOtherStyle',
                        component: () => import('@/views/setting/OtherStyle.vue'),
                    },
                    {
                        path: '/setting/other/lang',
                        name: 'settingOtherLang',
                        component: () => import('@/views/setting/OtherLang.vue'),
                    }
                ]
            },
            {
                path: '/trend',
                name: 'trend',
                component: () => import('@/views/Trend.vue'),
                meta: {title: '趋势'}
            }
        ]
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHashHistory(process.env.BASE_URL), //用于app，打包前开启
    routes
})

router.beforeEach((to:any, from:any, next:any) => {
    if(to.query && to.query.hasOwnProperty('twApp') || process.env.VUE_APP_SERVER_URL.indexOf('server.java') === -1) {
        if(!Token()) {
            if(!localData('is_first') || localData('is_first') == 0) {
                localData('is_first',1);
                store.commit('changeLoginType',1);
                store.commit('changeLogin',true);
            }
        }
        localData('twApp',1);
        store.commit('changeSafeHeight',0);
        store.commit('changeTwApp',1);
    }else{
        if(!localData('twApp')) {
            localData('twApp',null);
            store.commit('changeTwApp',null);
        }else{
            store.commit('changeTwApp',1);
        }
    }

    if(localData('backgroundType')) {
        let style = localData('backgroundType');
        let dom:any = document.getElementsByTagName('html')[0] as any;
        if(style == 1) {
            dom.classList.add('dark');
        }else{
            dom.classList.remove('dark');
        }
    }
    if(to.name != 'explore' && to.name != 'tweets' && to.name != 'userDetail' && to.name != 'circle' && to.name != 'download' && ((Token() && !Token().userInfo.id) || !Token())) {
        router.push({name: 'explore'});
        return;
    }
    if (loadingInstance && typeof loadingInstance.close === "function") {
        loadingInstance.close();
        loadingInstance = null;
    }
    loadingInstance = ElLoading.service({
        fullscreen: true,
        background: 'rgba(0, 0, 0, 0.7)',
    });
    store.commit('changePageTitle',getKeyVal(to,'meta.title',''));
    store.commit('changeRoute',to.name);
    store.commit('changeMenu',false);
    document.documentElement.scrollTop = 0;
    next();
});

router.afterEach((to:any, from:any) => {
    if (loadingInstance && loadingInstance.hasOwnProperty('close') && typeof loadingInstance.close === "function") {
        loadingInstance.close();
        loadingInstance = null;
    }
});
export default router
