
import {Options, Vue} from 'vue-class-component';
import {localData} from '@/lib/data';
import {Token} from '@/lib/access';
import store from '@/store';
@Options({
    mounted() {
        if(localData('backgroundType')) {
            let dom:any = document.getElementsByTagName('html')[0] as any;
            if(localData('backgroundType') == 1) {
                dom.classList.add('dark');
            }else{
                dom.classList.remove('dark');
            }
        }
    }
})
export default class App extends Vue {

}
