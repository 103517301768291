import {createStore} from 'vuex'

export default createStore({
    state: {
        menuStatus: false,
        nowRoute: '',
        pageTitle: '',
        showLogin: false,
        loginType: -1,
        userInfo: null,
        keyword: '',
        qInfo: null,
        sendInfo: {},
        showSendBox: false,
        twApp: 0,
        showVipDialog: false,
        vipType: 0,
        logoutDialog: false,
        updateBanner: false,
        safeHeight: 0,
        hideTw: []
    },
    getters: {},
    mutations: {
        changeMenu(state:any, val:any) {
            state.menuStatus = val;
        },
        changeRoute(state:any, val:any) {
            state.nowRoute = val;
        },
        changePageTitle(state:any, val:any) {
            state.pageTitle = val;
        },
        changeLoginType(state:any, val:any) {
            state.loginType = val;
        },
        changeLogin(state:any, val:any) {
            state.showLogin = val;
        },
        changeUserInfo(state:any, val:any) {
            state.userInfo = val;
        },
        changeKeyword(state:any, val:any) {
            state.keyword = val;
        },
        changeQInfo(state:any, val:any) {
            state.qInfo = val;
        },
        changeSendInfo(state:any, val:any) {
            state.sendInfo = val;
        },
        toggleSendBox(state:any, val:any) {
            state.showSendBox = val;
        },
        changeTwApp(state:any, val:any) {
            state.twApp = val;
        },
        changeVipDialog(state:any, val:any) {
            state.showVipDialog = val;
        },
        changeVipType(state:any, val:any) {
            state.vipType = val;
        },
        changeLogoutDialog(state:any, val:any) {
            state.logoutDialog = val;
        },
        changeBanner(state:any, val:any) {
            state.updateBanner = val;
        },
        changeSafeHeight(state:any, val:any) {
            state.safeHeight = val;
        },
        changeHideTw(state:any, val:any) {
            state.hideTw = val;
        },
    },
    actions: {},
    modules: {}
})
